import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import { CardActionArea, CardActions, CardContent, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/styles";
import { fetchActiveOrders, repeatOrder } from "../actions";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import { renderDateTime } from "../utils/datetimeUtils";

const useStyles = makeStyles({
  header: {
    paddingBottom: "10px",
  },
  image: {
    maxWidth: "100px",
    borderRadius: 50,
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: 30,
  },
  orderItem: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 0px",
  },
  categoryTitle: {
    // fontStyle: "b",
    fontVariant: "ruby",
  },
  additives: {
    paddingLeft: 10,
  },
  price: {
    textAlign: "left",
  },
});

const History = (props) => {
  const classes = useStyles();
  const { orders, fetchActiveOrders, repeatOrder } = props;
  // const clientState = useContext(StateContext);

  useEffect(() => {
    fetchActiveOrders();
  }, [fetchActiveOrders]);

  return (
    <div>
      <Typography className={classes.header} variant={"h6"}>
        Активные Заказы
      </Typography>
      {orders && orders.map((order) => <HistoryItem order={order} onOrderRepeat={repeatOrder} key={order.id} />)}
    </div>
  );
};

const HistoryItem = ({ order }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <Card>
      <CardActionArea disableRipple onClick={() => setOpen(!open)}>
        <div className={classes.cardHeader}>
          <Grid container>
            <Grid item xs={6} md={4}>
              <Typography>{renderDateTime(order.created)}</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography>Заказ № {order.id} </Typography>
            </Grid>
            {/*<Grid item xs={6}>*/}
            {/*    {order.delivery === true &&*/}
            {/*    <Typography>Доставка</Typography>*/}
            {/*    }*/}
            {/*    {order.delivery === false &&*/}
            {/*    <Typography>Самовывоз</Typography>*/}
            {/*    }*/}
            {/*</Grid>*/}
          </Grid>
          {/*<Typography>{(order.contact_information)}</Typography>*/}
          {/*<Typography className={classes.totalPrice}>{order.order_price}&#8381;</Typography>*/}
        </div>
        {/*{order.contact_information.map((contact_information) => (*/}
        {/*    <div key={contact_information.phone}>*/}
        {/*      <Typography>{contact_information.address_value}</Typography>*/}
        {/*    </div>*/}
        {/*))}*/}
      </CardActionArea>
      <Collapse in={open}>
        <CardContent>
          {order.order.map((orderItem) => (
            <div>
              <Grid container spacing={0} key={orderItem.id} className={classes.orderItem}>
                {/*<Grid item xs={2}*/}
                {/*      md={"auto"}>*/}
                {/*    <img className={classes.image} alt={orderItem.title} src={orderItem.photo}/>*/}
                {/*</Grid>*/}
                <Grid item xs={7} md={3}>
                  <Typography variant={"body1"}>
                    <img className={classes.image} alt={orderItem.title} src={orderItem.photo} />
                    <br />
                    {orderItem.title} &#215; {orderItem.quantity}
                  </Typography>
                </Grid>
                <Grid item xs={5} md={4} className={classes.price}>
                  {orderItem.additive.length > 0 && (
                    <div>
                      <Typography className={classes.categoryTitle}>Наполнитель</Typography>
                      {orderItem.additive.map((additive) => (
                        <Typography variant={"body2"} className={classes.additives} key={additive.id}>
                          {/*&#43;*/}
                          {additive.title} &#215; {orderItem.quantity}
                        </Typography>
                      ))}
                    </div>
                  )}
                  {orderItem.sauce &&
                    orderItem.sauce.map((sauce) =>
                      sauce.title === "None" || sauce.title === "Без соуса" ? null : (
                        <div key={sauce.item.title}>
                          <Typography className={classes.categoryTitle}>{sauce.title}</Typography>
                          <Typography className={classes.additives}>
                            {/*&#43;*/}
                            {sauce.item.title} &#215; {orderItem.quantity}
                          </Typography>
                        </div>
                      )
                    )}
                </Grid>
              </Grid>
              <hr />
            </div>
          ))}
        </CardContent>
        <CardActions>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Grid container md={"auto"} alignItems={"center"}>
                <Grid item>
                  <RestaurantIcon style={{ marginLeft: 10 }} />
                </Grid>
                <Grid item>
                  <Typography variant={"h5"}>&#215;{order.tableware} </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              {order.delivery === true && (
                //     <LocalShippingIcon color={"secondary"} fontSize={"large"} style={{marginLeft: 10}} />
                <Typography variant={"body1"}>Доставка</Typography>
              )}
              {
                order.delivery === false && <Typography variant={"body1"}>Самовывоз</Typography>
                // <EmojiPeopleIcon fontSize={"large"}  color={"primary"} style={{marginLeft: 10}}/>
              }
            </Grid>
            <Grid item style={{ alignItems: "flex-end" }} xs={4}>
              <Typography style={{ textAlign: "right", marginRight: 10 }} variant={"h6"}>
                {order.order_price}&#8381;
              </Typography>
            </Grid>
          </Grid>
        </CardActions>
        {/*<hr/>*/}
        {/*<CardActions style={{ justifyContent: "flex-end" }}>{renderedRepeatBtn()}</CardActions>*/}
      </Collapse>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    orders: state.orders.history,
  };
};
export default connect(mapStateToProps, {
  fetchActiveOrders,
  repeatOrder,
})(History);
