import React, {useEffect, useState} from "react";
import {Fab, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import bb from "../../api/boostBox";
import Loader from "../features/Loader";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        borderRadius: 10
    },
    header: {
        paddingBottom: theme.spacing(2),
    },
    secondTitle: {
        fontSize: "1.1rem",
        fontWeight: "bold",
        paddingTop: 12,
    },
}));

const Contacts = ({organization}) => {
    const classes = useStyles();
    const [organizationInfo, setOrganizationInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadOrganizationData = async (organizationId) => {
            let organization = await bb.Organizations.details({id: organizationId});

            if (organization.length) {
                organization = organization[0];
                return organization;
            }
            return {};
        };
        if (organization.organizationId) {
            loadOrganizationData(organization.organizationId)
                .then((response) => setOrganizationInfo(response))
                .then((_) => setLoading(false));
        }
    }, [organization.organizationId]);

    if (loading) return <Loader/>;

    return (
        <div>
            <Typography className={classes.header} variant={"h6"}>
                Контакты
            </Typography>
            <Paper  variant={"outlined"} className={classes.root}>
                <Typography variant={"inherit"}>
                    ИНН: {organizationInfo?.inn}
                    <br/>
                    Юридический адрес: {organizationInfo?.legal_address}
                </Typography>
            </Paper>
            <br/>
            <br/>
            <Grid container spacing={1}>
                {organizationInfo.instagram_link && (
                    <Grid item xs={6}>
                        <center>
                            <Typography variant={"h6"}>
                                <Fab style={{
                                    background: "white"
                                }}
                                     target="_blank"
                                     rel="noopener noreferrer"
                                     href={organizationInfo.instagram_link}
                                >
                                    <InstagramIcon style={{
                                        borderRadius: 10,
                                        color: "white",
                                        background: 'radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)',
                                    }} fontSize={"large"}/>
                                </Fab>
                            </Typography>
                        </center>
                    </Grid>
                )}
                {organizationInfo.whatsapp_link && (
                    <Grid item xs={6}>
                        <center>
                            <Typography variant={"h6"}>
                                <Fab style={{
                                    background: 'white',
                                    color: "#43d854"

                                }}
                                     target="_blank"
                                     rel="noopener noreferrer"
                                     href={organizationInfo.whatsapp_link}
                                     color='inherit'>
                                    <WhatsAppIcon fontSize={"large"}/>
                                </Fab>
                            </Typography>
                        </center>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

const mapStateToProps = (state) => ({organization: state.organization});

export default connect(mapStateToProps, null)(Contacts);
