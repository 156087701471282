import { combineReducers } from "redux";
import userReducer from "./userReducer";
import ordersReducer from "./ordersReducer";
import cartReducer from "./cartReducer";
import errorReducer from "./errorReducer";
import organizationReducer from "./organizationReducer";

export default combineReducers({
  // state: clientParamsReducer,
  user: userReducer,
  cart: cartReducer,
  orders: ordersReducer,
  errors: errorReducer,
  organization: organizationReducer,
});
