import React from "react";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles({
  quantityDiv: {
    display: "flex",
    padding: "8px 0px",
    alignItems: "center",
    "& p": {
      fontSize: "1.2rem",
    },
  },
  quantityButtons: {
    borderRadius: 12,
    backgroundColor: "#eaeaea",
    padding: 6,
    "&:hover": {
      backgroundColor: "#eaeaea",
    },
  },
  quantity: {
    padding: "0px 6px",
  },
});

const QuantityDiv = ({ quantity, onIncrement, onDecrement }) => {
  const classes = useStyles();
  return (
    <div className={classes.quantityDiv}>
      <IconButton onClick={onDecrement} className={classes.quantityButtons} disableRipple>
        <RemoveIcon />
      </IconButton>
      <Typography className={classes.quantity}>{quantity}</Typography>
      <IconButton onClick={onIncrement} className={classes.quantityButtons} disableRipple>
        <AddIcon />
      </IconButton>
    </div>
  );
};

export default QuantityDiv;
