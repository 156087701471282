import { addDays } from "./datetimeUtils";

export const getWorkDay = (workingHours) => {
  const nowDt = new Date();

  const today = workingHours.filter(
    (d) => d.day === nowDt.toLocaleDateString("en-us", { weekday: "long" }).toLowerCase()
  );
  const tomorrow = workingHours.filter(
    (d) => d.day === addDays(1, nowDt).toLocaleDateString("en-us", { weekday: "long" }).toLowerCase()
  );

  if (!today.length || !tomorrow.length) return { open: false };

  const [startHour, startMinutes, ...startSeconds] = today[0].start_time.split(":");
  const [endHour, endMinutes, ...endSeconds] = today[0].end_time.split(":");
  const [tomorrowStartHour, tomorrowStartMinutes, ...tomorrowStartSeconds] = tomorrow[0].start_time.split(":");

  const start_time = new Date(
    nowDt.getFullYear(),
    nowDt.getMonth(),
    nowDt.getDate(),
    parseInt(startHour),
    startMinutes
  );
  const end_time = new Date(
    nowDt.getFullYear(),
    nowDt.getMonth(),
    nowDt.getDate(),
    parseInt(endHour),
    parseInt(endMinutes)
  );

  const hours = {
    start_time,
    end_time,
    next_start_time:
      nowDt.getHours() > parseInt(endHour) || nowDt.getHours() >= parseInt(startHour)
        ? new Date(
            nowDt.getFullYear(),
            nowDt.getMonth(),
            nowDt.getDate() + 1,
            parseInt(tomorrowStartHour),
            parseInt(tomorrowStartMinutes)
          )
        : start_time,
  };
  hours.open = nowDt > hours.start_time && nowDt < hours.end_time;
  return hours;
};
