import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { FormHelperText, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  selectControl: {
    marginTop: theme.spacing(1),
  },
}));

const SelectFromList = ({ title, options, onSelect, errors, index, id }) => {
  const classes = useStyle();
  const [selected, setSelected]= useState("");

  const handleSelect = (e) => {
    setSelected(e.target.value);
    onSelect({
      [index]: {
        title: title,
        item: options.filter((item) => item.id === e.target.value)[0],
        id: id
      },
    });
  };

  return (
    <FormControl className={classes.selectControl} fullWidth error={errors !== undefined && errors.length > 0}>
      <InputLabel htmlFor={title}>{title}</InputLabel>
        {/*#todo show price*/}
      <Select name={title} onChange={handleSelect} value={selected}>
        {options.map((item) => (
          <MenuItem key={item.id} value={item.id} name={item.title}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
      {errors && <FormHelperText>{errors}</FormHelperText>}
    </FormControl>
  );
};

export default SelectFromList;
