import { boostBoxClient as gb } from "./index";

export const createPayment = async (order) => {
  try {
    const response = await gb.post("/yoomoney/create_payment", {
      amount: {
        value: order.total_price,
        currency: "RUB",
      },
      confirmation: {
        type: "embedded",
      },
      capture: true,
    });
    return JSON.parse(response.data);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchPaymentStatus = async (paymentId) => {
  try {
    const response = await gb.post("/yoomoney/payment_status", { payment_id: paymentId });
    return JSON.parse(response.data);
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};
