export const calcTotalPrice = (menuItem) => {
  let updatedPrice = menuItem.portion.price;
  if (menuItem.additives.length > 0) {
    updatedPrice += menuItem.additives.reduce((t, c) => t + c.price, 0);
  }
  if (menuItem.modifiers.length > 0) {
    updatedPrice += menuItem.modifiers.reduce((t, c) => t + c.item.price, 0);
  }
  updatedPrice *= menuItem.quantity;
  return updatedPrice;
};
