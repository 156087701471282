import { boostBoxClient as gb } from "./index";

export const updateOrder = async (orderId, values) => {
  try {
    return await gb.patch(`/order/${orderId}/`, values);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getUserOrders = async (userId) => {
  try {
    return await gb
      .get("/order/", {
        params: {
          user_id: userId,
        },
      })
      .then((response) => response.data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const deliveryCreateDraft = async (address) => {
  const response = await gb
    .post("/delivery/create_draft", {
      client: address.name,
      phone: address.phone,
      endpoint_address: address.address_value,
      lat: parseFloat(address.lat),
      long: parseFloat(address.long),
      porch: address.entrance,
      floor: address.floor,
      flat: address.apartment,
      door_code: address.doorCode,
      comment: address.comment,
    })
    .catch((e) => {
      console.log(e);
      return null;
    });
  return response.data;
};

export const sendPromoCode = async (promoCode, phone = "") => {
  try {
    const response = await gb.post("/sale/promocode", {
      promo_code: promoCode,
      phone,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
