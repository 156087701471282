import { navigate } from "@reach/router";
import { CREATE_ORDER, POST_ORDER } from "./types";
import bb from "../api/boostBox";
import { runRequest } from "../api";
import { clearCart } from "./cartActions";

export const saveCurrentOrder = (order) => async (dispatch, getState) => {
  const { organization, user } = getState();
  const orderData = {
    ...order,
    status: 0,
    user_id: user.user_id,
    organization_id: organization.organizationId,
    restaurant_id: organization.currentRestaurant,
  };

  if (organization.paymentsEnabled) {
    await navigate("/payment", { state: { order: order } });
    dispatch({ type: CREATE_ORDER, payload: orderData });
  } else {
    orderData.payment_method = null;
    const response = await runRequest(bb.Orders.create, {
      ...orderData,
      contact_information: JSON.stringify(orderData.contact_information),
      order: JSON.stringify(order.order),
      payment_method: "cash",
    });
    if (response.success) {
      dispatch({ type: POST_ORDER, success: true, payload: order });
      await navigate("/order_status");
      dispatch(clearCart); // TODO: test this
    } else {
      await navigate("/cart", { state: { error: { message: "Что-то пошло не так. Попробуйте повторить позднее" } } });
    }
  }
};

export const postOrder = (order) => async (dispatch) => {
  try {
    const response = await bb.Orders.create({
      ...order,
      contact_information: JSON.stringify(order.contact_information),
      order: JSON.stringify(order.order),
    });
    dispatch({ type: POST_ORDER, success: true, payload: order });
    return response.data.id;
  } catch (e) {
    console.log(e);
    // dispatch({
    //   type: POST_ORDER_ERROR,
    //   success: false,
    //   payload: {
    //     message: "Что-то пошло не так. Попробуйте повторить позднее",
    //     data: e.response
    //   }
    // })
    throw e;
  }
};
