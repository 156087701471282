import React, { useEffect, useReducer, useState } from "react";
import "./App.css";
import { Location, Router } from "@reach/router";
import Drawer from "./components/navbar/drawer";
import OrderStatus from "./components/order/OrderStatus";
import { ClientContext, clientParamsReducer, initialState, StateContext } from "./reducers/reducer";
import OrderHistory from "./components/order/OrderHistory";
import Container from "@material-ui/core/Container";
import { getUserCredentials } from "./actions/userActions";
import Contacts from "./components/organization/Contacts";
import YookassaForm from "./components/payment/yookassaForm";
import { YMInitializer } from "react-yandex-metrika";
import PaymentValidator from "./components/payment/PaymentValidator";
import OrdersForStaff from "./components/OrdersForStaff";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Loader from "./components/features/Loader";
import { getOrganizationInfo } from "./actions/organizationActions";
import { connect } from "react-redux";
import bb from "./api/boostBox";
import MenuList from "./components/menu/MenuList";
import MobileMenuList from "./components/menu/MobileMenuList";
import Cart from "./components/cart/Cart";

const theme = createTheme({
  typography: {
    captionSm: {
      fontSize: 12
    },
    caption: {
      fontSize: 14
    },
    body1: {
      fontSize: 16
    }
  },
  palette: {
    secondary: {
      // main: green[900],
      main: "#5B61F2"
    }
  }
});

function App({ organization, getOrganizationInfo, getUserCredentials }) {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 900);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [loading, setLoading] = useState(true);
  const [restaurant, setRestaurant] = useState({ menu: [] });

  // todo: remove this
  const [clientParams, dispatch] = useReducer(clientParamsReducer, initialState);

  useEffect(() => {
    const loadData = async () => {
      await getOrganizationInfo();
      await getUserCredentials();
    };
    loadData();
  }, [getOrganizationInfo, getUserCredentials]);

  useEffect(() => {
    const loadRestaurantData = async (restaurantId) => {
      const response = await bb.Restaurants.details(restaurantId);
      if (response) {
        response.menu.forEach((menuItem) => (menuItem.portion_size = JSON.parse(menuItem.portion_size)));
        return response;
      }
      return { menu: [] };
    };

    if (organization.currentRestaurant) {
      loadRestaurantData(organization.currentRestaurant)
        .then((response) => setRestaurant(response))
        .then((_) => setLoading(false));
    }
  }, [organization.currentRestaurant]);

  if (loading) return <Loader />;

  return (
    <ThemeProvider theme={theme}>
      <Location>
        <ClientContext.Provider value={dispatch}>
          <StateContext.Provider value={clientParams}>
            <div>
              <YMInitializer
                accounts={[72845197]}
                options={{
                  webvisor: true,
                  clickmap: true,
                  trackLinks: true,
                  accurateTrackBounce: true
                }}
              />
              <Drawer />
              <Container maxWidth={"lg"} style={{ marginBottom: 40 }}>
                <Router>
                  {isMobile ?
                    <MobileMenuList default menu_list={restaurant.menu} path={"/"} />
                    :
                    <MenuList default menu_list={restaurant.menu} path={"/"} />
                  }
                  <Cart restaurant={restaurant} path={"/cart"} />
                  <OrderStatus path={"/order_status"} />
                  <OrderHistory path={"/order_history"} />
                  <Contacts path={"/contacts"} />
                  <YookassaForm path={"/payment"} />
                  <OrdersForStaff path={"/staff/orders"} />
                  <PaymentValidator path={"/payment_check/:orderId/:paymentId"} />
                </Router>
              </Container>
            </div>
          </StateContext.Provider>
        </ClientContext.Provider>
      </Location>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({ organization: state.organization });

export default connect(mapStateToProps, { getOrganizationInfo, getUserCredentials })(App);
