import React from "react";
import * as actions from "../actions/types";
import isEqual from "lodash/isEqual";

export const StateContext = React.createContext(null);
export const ClientContext = React.createContext(null);

export const initialState = {
  userId: "",
  tableNumber: 9999,
  paymentMethod: "card",
  tableNumberValid: true,
  error: "",
  preorders: [],
  totalPricePreOrdered: 0,
  orders: [],
  orderHistory: [],
  open: false,
};

const ordersEqual = (orderA, orderB) => {
  return (
    orderA.id === orderB.id && isEqual(orderA.additive, orderB.additive) && isEqual(orderA.sauce, orderB.sauce)
  );
};

export const clientParamsReducer = (state, action) => {
  // console.log('reducer', action);
  switch (action.type) {
    case actions.SAVE_ORDER:
      return {
        ...state,
        orders: JSON.parse(action.payload.order),
      };
    case actions.FETCH_CURRENT_ORDER:
      return {
        ...state,
        orders: action.payload,
      };
    case actions.FETCH_ALL_USER_ORDERS:
      return { ...state, orderHistory: action.payload };
    case actions.UPDATE_ORDERS:
      return { ...state, orders: action.payload };
    case actions.GET_USER_ID:
      return { ...state, userId: action.payload };
    case actions.UPDATE_CART_ITEM_STATE:
      const i = state.preorders.findIndex((item) => ordersEqual(item, action.payload));
      if (i >= 0) {
        let preordersTemp = state.preorders;
        preordersTemp[i] = action.payload;
        return { ...state, preorders: preordersTemp };
      }
      return state;
    case actions.SET_CLIENT_PARAMS:
      return {
        ...state,
        tableNumber: action.payload.tableNumber,
        paymentMethod: action.payload.paymentMethod,
      };
    case actions.CHANGE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload.paymentMethod,
      };
    case actions.CHANGE_TABLE_NUMBER:
      return {
        ...state,
        tableNumberValid: true,
        tableNumber: action.payload.tableNumber,
      };
    case actions.TABLE_NUMBER_EMPTY:
      return {
        ...state,
        tableNumberValid: false,
        error: "",
      };
    case actions.TABLE_NUMBER_ERROR:
      return {
        ...state,
        tableNumberValid: false,
        error: "Неверный номер стола",
      };
    case actions.OPEN_CLIENT_PARAMS_DIALOG:
      return {
        ...state,
        open: true,
      };
    case actions.CLOSE_CLIENT_PARAMS_DIALOG:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export default clientParamsReducer;
