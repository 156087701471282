import { GET_ORGANIZATION_DATA } from "../actions/types";

const initialState = {
  organizationId: null,
  title: "",
  currentRestaurant: null,
  restaurants: [],
  paymentsEnabled: false,
  deliveryEnabled: false,
};

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_DATA:
      return { ...state, ...action.payload, currentRestaurant: action.payload.restaurants[0] };
    default:
      return state;
  }
};

export default organizationReducer;
