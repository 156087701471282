import {
  GET_USER_ADDRESS,
  GET_USER_CREDENTIALS,
  GET_USER_ID,
  REGISTER_USER,
  SAVE_USER_ADDRESS,
} from "../actions/types";

const initialState = {
  address: null,
  token: null,
  user_id: null,
  username: null,
  registered: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_ID:
      return { ...state, userId: action.payload };
    case GET_USER_CREDENTIALS:
      return action.success ? { ...state, ...action.payload, registered: true } : { ...state, registered: false };
    case GET_USER_ADDRESS:
      return { ...state, address: action.payload };
    case REGISTER_USER:
      return action.success ? { ...state, ...action.payload, registered: true } : { ...state, registered: false };
    case SAVE_USER_ADDRESS:
      return { ...state, address: action.payload };
    default:
      return state;
  }
};

export default userReducer;
