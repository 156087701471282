import React from "react";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { Link } from "@reach/router";
import Modal from "../features/Modal";

const OrderOrContinue = ({ isOpen, onClose }) => {

    const renderContent = () => {
        return <Typography>Перейти к заказу или продолжить выбор?</Typography>;
    };

    const renderActions = () => {
        return [
          <Button key={"toOrder"} color={"secondary"} component={Link} to={"/cart"} disableRipple>
            Заказать
          </Button>,
          <Button key={"toClose"} color={"secondary"} onClick={onClose}>
            Продолжить
          </Button>,
        ];
    };

    return (
        <Modal
            key={"order_continue"}
            open={isOpen}
            onClose={onClose}
            title={null}
            content={renderContent()}
            actions={renderActions()}
        />
    );
};

export default OrderOrContinue;
