import {
  ADD_INTO_CART,
  CHANGE_CART_ITEM_QUANTITY,
  CLEAR_CART,
  DELETE_FROM_CART,
  GET_CART_ITEMS,
  UPDATE_CART_ITEM,
} from "../actions/types";

const initialState = {
  items: [],
  count: 0,
  promoCodes: null,
  totalPrice: null,
};

const countCartItems = (items) => {
  let count = 0;
  for (let i = 0; i < items.length; i++) {
    count += items[i].quantity;
  }
  return count;
};

const calcTotalPrice = (items) => {
  return items.reduce((t, c) => t + c.totalPrice, 0);
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CART_ITEMS:
      return {
        ...state,
        items: action.payload,
        count: countCartItems(action.payload),
        totalPrice: calcTotalPrice(action.payload),
      };
    case ADD_INTO_CART:
      return {
        ...state,
        items: action.payload,
        count: countCartItems(action.payload),
        totalPrice: calcTotalPrice(action.payload),
      };
    case UPDATE_CART_ITEM:
      return {
        ...state,
        items: action.payload,
        count: countCartItems(action.payload),
        totalPrice: calcTotalPrice(action.payload),
      };
    case CHANGE_CART_ITEM_QUANTITY:
      return {
        ...state,
        items: action.payload,
        count: countCartItems(action.payload),
        totalPrice: calcTotalPrice(action.payload),
      };
    case DELETE_FROM_CART:
      return {
        ...state,
        items: action.payload,
        count: countCartItems(action.payload),
        totalPrice: calcTotalPrice(action.payload),
      };
    case CLEAR_CART:
      return {
        ...state,
        items: [],
        count: 0,
        totalPrice: 0,
      };
    // case APPLY_DISCOUNT:
    //   let promoCode = Object.keys(action.payload);
    //   if (state.promoCodes) {
    //     const appliedCodes = Object.keys(state.promoCodes);
    //     promoCode = Object.keys(action.payload).filter((x) => !appliedCodes.includes(x.toLowerCase().trim()));
    //   }
    //
    //   if (state.promoCodes === null || (Array.isArray(promoCode) && promoCode?.length)) {
    //     const discountAmount = Math.round((state.totalPrice / 100) * action.payload[promoCode]);
    //     return {
    //       ...state,
    //       totalPrice: state.totalPrice - discountAmount,
    //       promoCodes: { ...state.promoCodes, ...action.payload },
    //     };
    //   }
    //   return state;
    // case RESET_DISCOUNT:
    //   return {
    //     ...state,
    //     promoCodes: null,
    //     totalPrice: calcTotalPrice(action.payload),
    //   };
    default:
      return state;
  }
};

export default cartReducer;
