import React from "react";
import Button from "@material-ui/core/Button";
import {
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import InstagramIcon from "@material-ui/icons/Instagram";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { AddressSuggestions } from "react-dadata";
import "react-dadata/dist/react-dadata.css";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import NumberFormat from "react-number-format";
// import { withFormikDevtools } from "formik-devtools-extension";
import Modal from "../features/Modal";
import PrivacyPolicy from "../policies/PrivacyPolicy";
import { getUserAddress } from "../../actions/userActions";
import { getDeliveryPrice } from "../../actions";

const tables = [
  { table: 1, enabled: "true" },
  { table: 2, enabled: "true" },
  { table: 3, enabled: "true" },
  { table: 4, enabled: "true" },
  { table: 5, enabled: "true" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 8,
  },
  error: {
    border: "1px solid #f44336",
  },
  wideField: {
    width: "29ch",
  },
  spacedField: {
    padding: "10px 0px",
  },
  actionButton: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 0px 0px 0px",
  },
  pricesDiv: {
    marginTop: theme.spacing(1),
    textAlign: "right",
  },
  icon: {
    marginRight: 5,
  },
  totalPrice: {
    fontSize: "1.1rem",
  },
  privacyPolicyDiv: {
    marginTop: theme.spacing(1),
  },
  privacyPolicy: {
    fontSize: "0.9rem",
  },
}));

const OrderForm = ({ open, onClose, onSubmit, restaurant, price }) => {
  const classes = useStyles();

  const user = useSelector((state) => state.user.address);
  const organization = useSelector((state) => state.organization);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const phoneRegex = /^(\+?7)?[94]\d{9}$/;

  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const [courierDelivery, setCourierDelivery] = React.useState(organization.deliveryEnabled);
  const [deliveryPrice, setDeliveryPrice] = React.useState(0);
  const [deliveryLoading, setDeliveryLoading] = React.useState(false);
  const [deliveryEta, setDeliveryEta] = React.useState(0);

  const [address, setAddress] = React.useState("");
  const [tableValue, setTableValue] = React.useState(0);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getUserAddress());
  }, [dispatch]);

  React.useEffect(() => {
    if (user && user.phone) {
      setPhoneNumber(user.phone.replace(/^7|^\+7|^8/, ""));
    }
    if (user && user.address_value) {
      setAddress({
        data: {
          street_with_type: user.street,
          house: user.streetBuilding,
          block: user.building,
          geo_lat: user.lat,
          geo_lon: user.long,
        },
        value: user.address_value,
      });
    }
  }, [user]);

  const initialValues = {
    name: user ? user.name : "",
    delivery: organization.deliveryEnabled,
    deliveryPrice: "",
    address: user ? user.address_value : "",
    house: user ? user.streetBuilding ?? "" : "",
    phone: user ? user.phone : "",
    apartment: user ? user.apartment : "",
    entrance: user ? user.entrance : "",
    doorCode: user ? user.doorCode : "",
    floor: user ? user.floor : "",
    instagram: user ? user.instagram : "",
    comment: user ? user.comment : "",
    privacyPolicy: false,
  };

  const validationShape = Yup.object().shape({
    address: Yup.string().when("delivery", { is: true, then: Yup.string().required("Введите адрес") }),
    house: Yup.string()
      .when("delivery", { is: true, then: Yup.string().required("Введите номер дома") })
      .nullable(),
    name: Yup.string().required("Введите имя"),
    phone: Yup.string().required("Введите номер телефона").min(10, "").matches(phoneRegex, "Неверный формат"),
    delivery: Yup.boolean(),
    deliveryPrice: Yup.number().when("delivery", { is: true, then: Yup.number().required() }),
    privacyPolicy: Yup.boolean()
      .required()
      .oneOf([true], "Чтобы продолжить, примите согласие на обработку персональных данных"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationShape,
    validate: () => {
      let errors = {};
      if (courierDelivery && (!deliveryPrice || deliveryPrice === 0)) {
        errors.deliveryPrice = "Delivery price is required";
      }
      return errors;
    },
    onSubmit: (values) => {
      onSubmit({
        ...values,
        dadata: address,
        delivery: courierDelivery,
        deliveryPrice: deliveryPrice,
        table: tableValue,
      });
    },
    enableReinitialize: true,
  });
  // withFormikDevtools(formik);
  const [submitCount, setSubmitCount] = React.useState(formik.submitCount);

  React.useEffect(() => {
    if (open && !formik.isValid && formik.submitCount > submitCount) {
      let firstErrorKey = Object.keys(formik.errors).filter((x) => x !== "deliveryPrice")[0];
      firstErrorKey = firstErrorKey === "house" ? "address" : firstErrorKey;
      if (global.window.document.getElementsByName(firstErrorKey).length) {
        global.window.document.getElementsByName(firstErrorKey)[0].focus();
      }
      setSubmitCount(formik.submitCount);
    }
  }, [formik.submitCount, formik.isValid, formik.errors]);

  const handleSuggestionSelect = (suggestion) => {
    formik.setFieldValue("address", suggestion.value);
    if (suggestion.data.house === null) {
      formik.setFieldValue("house", "");
    } else {
      formik.setFieldValue("house", suggestion.data.house);
    }
    setAddress(suggestion);
  };

  const getPrice = async () => {
    if (
      open &&
      courierDelivery &&
      formik.values.name &&
      formik.values.phone &&
      address.data?.street_with_type &&
      address.data?.house
    ) {
      setDeliveryLoading(true);
      const response = await getDeliveryPrice({
        client: formik.values.name,
        phone: formik.values.phone,
        endpoint_address: address.value
          ? address.value
          : "г Москва, " + address.data.street_with_type + ", " + address.data.house + "," + address.data.block,
        lat: parseFloat(address.data.geo_lat),
        long: parseFloat(address.data.geo_lon),
        porch: formik.values.entrance ?? "",
        floor: formik.values.floor ?? "",
        flat: formik.values.apartment ?? "",
        door_code: formik.values.doorCode ?? "",
        order_amount: price,
      });
      setDeliveryLoading(false);
      setDeliveryPrice(response.dlv_amount_for_client);
      setDeliveryEta(response.eta);
      await formik.setFieldValue("deliveryPrice", response.dlv_amount_for_client);
    }

    return null;
  };

  React.useEffect(() => {
    if (open && courierDelivery) {
      setDeliveryPrice(null);
      getPrice();
    }
  }, [open, courierDelivery]);

  const renderDeliveryPrice = () => {
    if (deliveryLoading) {
      return (
        <React.Fragment>
          Доставка: <CircularProgress size={20} />
        </React.Fragment>
      );
    }
    if (deliveryPrice !== null && deliveryPrice >= 0) {
      return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ display: "flex", alignItems: "center" }}>
            <TimelapseIcon className={classes.icon} /> {deliveryEta} мин.
          </span>
          <span>Доставка: {deliveryPrice}&#8381;</span>
        </div>
      );
    }
    return null;
  };

  const handleClose = () => {
    onClose();
  };

  const handleDeliverySelect = async (e) => {
    switch (e.target.value.toString().toLowerCase()) {
      // pickup
      case "false":
        setCourierDelivery(false);
        setDeliveryPrice(0);
        await formik.setFieldValue("delivery", false);
        await formik.setFieldValue("deliveryPrice", 0);
        break;
      // courier delivery
      case "true":
        await formik.setFieldValue("delivery", true);
        setDeliveryPrice(null);
        setCourierDelivery(true);
        await getPrice();
        break;
      default:
        return;
    }
  };

  const handlePhoneChanged = (number) => {
    let formatted = number.value.replace(/^7|^\+7|^8|^6|^5|^3|^2|^1/, "");
    if (formatted.length > 10) {
      formatted = formatted.slice(0, -1);
    }

    setPhoneNumber(formatted);
    formik.setFieldValue("phone", "+7" + formatted);
  };

  const renderContent = () => {
    return (
      <form className={classes.root} onSubmit={formik.handleSubmit}>
        <Grid container alignItems={"flex-end"} justifyContent={"center"} spacing={1}>
          <Grid item>
            <Collapse in={courierDelivery}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <AddressSuggestions
                    token={process.env.REACT_APP_DADATA_API_KEY}
                    filterLocations={[{ region: "Москва" }]}
                    minChars={4}
                    count={5}
                    delay={200}
                    inputProps={{
                      id: "address",
                      name: "address",
                      placeholder: "Адрес",
                      onBlur: getPrice,
                      onChange: formik.handleChange,
                    }}
                    defaultQuery={address.value}
                    onChange={handleSuggestionSelect}
                  />
                  {formik.errors.address && Boolean(formik.touched.address) && (
                    <p className='MuiFormHelperText-root Mui-error'>Введите адрес</p>
                  )}

                  {formik.errors.house && Boolean(formik.touched.address) && !formik.errors.address && (
                    <p className='MuiFormHelperText-root Mui-error'>{formik.errors.house}</p>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='apartment'
                    name='apartment'
                    label='Квартира'
                    onChange={formik.handleChange}
                    defaultValue={formik.values.apartment}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='entrance'
                    name='entrance'
                    label='Подъезд'
                    onChange={formik.handleChange}
                    defaultValue={formik.values.entrance}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='doorCode'
                    name='doorCode'
                    label='Домофон'
                    onChange={formik.handleChange}
                    defaultValue={formik.values.doorCode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='floor'
                    name='floor'
                    label='Этаж'
                    onChange={formik.handleChange}
                    defaultValue={formik.values.floor}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              className={classes.wideField}
              id='name'
              name='name'
              label='Имя'
              onBlur={getPrice}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              defaultValue={formik.values.name}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NumberFormat
              className={classes.wideField}
              name={"phone"}
              label={"Телефон"}
              type={"tel"}
              value={phoneNumber}
              onBlur={getPrice}
              customInput={TextField}
              InputProps={{
                startAdornment: <InputAdornment position={"start"}>+7</InputAdornment>,
              }}
              onValueChange={handlePhoneChanged}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              format='### ### ## ###'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={clsx(classes.spacedField, classes.wideField)}
              id='instagram'
              name='instagram'
              // label='Instagram аккаунт'
              onChange={formik.handleChange}
              placeholder={"instagram аккаунт"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={"start"}>
                    <InstagramIcon color={"action"} />
                  </InputAdornment>
                ),
              }}
              defaultValue={formik.values.instagram}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='comment'
              name='comment'
              label='Комментарий'
              fullWidth
              onChange={formik.handleChange}
              defaultValue={formik.values.comment}
            />
          </Grid>
          <Grid item xs={12}>
            <RadioGroup name='delivery' row value={courierDelivery} onChange={handleDeliverySelect}>
              {organization.deliveryEnabled && (
                <FormControlLabel value={true} control={<Radio size={"small"} />} label='Доставка' />
              )}
              {!restaurant.tables_present && (
                <FormControlLabel value={false} control={<Radio size={"small"} />} label='Самовывоз' />
              )}
            </RadioGroup>
            {restaurant.tables_present && (
              <Grid item xs={12} sm={12} md={12}>
                <FormControl variant='standard' fullWidth>
                  <InputLabel id='table-select-label'>Место</InputLabel>
                  <Select
                    labelId='table-select-label'
                    id='table-select'
                    defaultValue={tableValue}
                    onChange={(e) => setTableValue(e.target.value)}
                    label='table'
                  >
                    <MenuItem value={0}>с собой</MenuItem>
                    {restaurant.tables.map(
                      (item) =>
                        item.enabled && (
                          <MenuItem key={item.table} value={item.table}>
                            стол №{item.table}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Collapse in={!courierDelivery}>
              <Typography variant={"body2"}>{restaurant.address}</Typography>
            </Collapse>
          </Grid>

          <Grid item xs={12} className={classes.pricesDiv}>
            <Typography>Заказ: {price}&#8381;</Typography>
            {courierDelivery && renderDeliveryPrice()}
          </Grid>
          <Grid item xs={12}>
            <FormControl
              className={classes.privacyPolicyDiv}
              error={Boolean(formik.errors.privacyPolicy)}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                classes={{ label: classes.privacyPolicy }}
                control={<Checkbox name={"privacyPolicy"} />}
                label={
                  <Typography className={classes.privacyPolicy}>
                    Согласен(на) на{" "}
                    <Link onClick={() => setOpenPrivacyPolicy(true)}>обработку персональных данных</Link>
                  </Typography>
                }
              />
              {formik.touched.privacyPolicy && <FormHelperText>{formik.errors.privacyPolicy}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.actionButton}>
          <Typography className={classes.totalPrice} display={"inline"}>
            {price + deliveryPrice}&#8381;
          </Typography>
          {renderActions()}
        </div>
      </form>
    );
  };

  const renderActions = () => {
    return (
      <Button type={"submit"} color={"secondary"}>
        подтвердить
      </Button>
    );
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={handleClose} title={"Контактные данные"} content={renderContent()} />
      <PrivacyPolicy open={openPrivacyPolicy} onClose={() => setOpenPrivacyPolicy(false)} />
    </React.Fragment>
  );
};

export default OrderForm;
