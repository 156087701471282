import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import { CardActionArea, CardActions, CardContent, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import { fetchCompletedUserOrders, repeatOrder } from "../../actions";
import { renderDateTime } from "../../utils/datetimeUtils";
import bb from "../../api/boostBox";
import Loader from "../features/Loader";
import Modal from "../features/Modal";

const useStyles = makeStyles({
  header: {
    paddingBottom: "10px",
  },
  image: {
    maxWidth: "80px",
    borderRadius: 10,
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: 16,
  },
  orderItem: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 0px",
  },
  categoryTitle: {
    fontStyle: "italic",
  },
  additives: {
    paddingLeft: 10,
  },
  price: {
    textAlign: "left",
  },
});

const OrderHistory = () => {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const userId = useSelector((state) => state.user.user_id);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const loadUserOrders = async (userId) => {
      const response = await fetchCompletedUserOrders(userId);
      setOrders(response);
    };

    if (userId) {
      loadUserOrders(userId);
    }
    setLoading(false);
  }, [userId]);

  const onOrderRepeat = async (orderItems) => {
    dispatch(repeatOrder(orderItems));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Typography className={classes.header} variant={"h6"}>
        История заказов
      </Typography>
      {orders && orders.map((order) => <HistoryItem key={order.id} order={order} onOrderRepeat={onOrderRepeat} />)}

      <Modal
        open={error && Boolean(Object.keys(error).length)}
        content={
          <React.Fragment>
            {error?.menu.map((menuItem) => (
              <Typography key={menuItem}>Блюдо "{menuItem}" не доступно в данный момент</Typography>
            ))}
          </React.Fragment>
        }
        actions={[<Button onClick={() => setError(null)}>Отмена</Button>, <Button>Продолжить</Button>]}
        onClose={() => setError(null)}
      />
    </React.Fragment>
  );
};

const HistoryItem = ({ order, onOrderRepeat }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const checkOrderItems = async (orderItems) => {
    setLoading(true);
    let errors = {};
    for (const menuItem of orderItems) {
      try {
        await bb.Menu.details(menuItem.id);
      } catch (e) {
        console.log(e.message);
        if (e.response?.status === 404) {
          errors.menu ? errors.menu.push(menuItem.title) : (errors.menu = [menuItem.title]);
        }
      }
    }
    setLoading(false);

    if (Object.keys(errors).length) {
      setError(errors);
    } else {
      onOrderRepeat(orderItems);
    }
  };

  const removeNonAvailableItems = () => {
    let updatedItems = order.order;
    for (const missingMenuItem of error.menu) {
      updatedItems = updatedItems.filter((i) => i.title !== missingMenuItem);
    }
    onOrderRepeat(updatedItems);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Modal
        open={!!(error && Boolean(Object.keys(error).length))}
        content={
          <React.Fragment>
            {error?.menu.map((menuItem) => (
              <Typography key={menuItem} variant={"body2"}>
                Блюдо "{menuItem}" не доступно в данный момент
              </Typography>
            ))}
          </React.Fragment>
        }
        actions={[
          <Button key={"cancelBtn"} onClick={() => setError(null)}>
            Отмена
          </Button>,
          <Button key={"continueBtn"} onClick={removeNonAvailableItems}>
            Продолжить
          </Button>,
        ]}
        onClose={() => setError(null)}
      />
      <Card>
        <CardActionArea disableRipple onClick={() => setOpen(!open)}>
          <div className={classes.cardHeader}>
            <Typography>{renderDateTime(order.created)}</Typography>
          </div>
        </CardActionArea>
        <Collapse in={open}>
          <CardContent>
            {order.order.map((orderItem) => (
              <Grid container spacing={0} key={orderItem.id} className={classes.orderItem}>
                <Grid item xs={5} md={"auto"}>
                  <img className={classes.image} alt={orderItem.title} src={orderItem.photo} />
                </Grid>
                <Grid item xs={7} sm={3}>
                  <Typography variant={"body1"}>{orderItem.title}</Typography>
                </Grid>
                <Grid item xs={2} md={3}>
                  <Typography variant={"body1"}>&#215;{orderItem.quantity}</Typography>
                </Grid>
                <Grid item xs={7} md={4} className={classes.price}>
                  {orderItem.additives.map((additive) => (
                    <Typography variant={"body2"} className={classes.additives} key={additive.id}>
                      &#43; {additive.title}
                    </Typography>
                  ))}

                  {orderItem.modifiers.map((modifier) =>
                    modifier.title === "None" || modifier.title === "Без соуса" ? null : (
                      <div key={modifier.item.id}>
                        <Typography variant={"body2"} className={classes.additives}>
                          &#43; {modifier.item.title}
                        </Typography>
                      </div>
                    )
                  )}
                </Grid>
              </Grid>
            ))}
          </CardContent>
          <CardActions style={{ justifyContent: "flex-end" }}>
            <Button onClick={() => checkOrderItems(order.order)} color={"secondary"} variant='contained'>
              Повторить
            </Button>
          </CardActions>
        </Collapse>
      </Card>
    </React.Fragment>
  );
};

export default OrderHistory;
