import React, { useCallback, useEffect, useState } from "react";
import { fetchPaymentStatus } from "../../api/yookassa";
import { clearCart } from "../../actions/cartActions";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import { updateOrder } from "../../api/green_bowl";

const PaymentValidator = (props) => {
  const { orderId, paymentId, clearCart } = props;
  const [loading, setLoading] = useState(true);

  const checkPayment = useCallback(() => {
    setLoading(true);

    fetchPaymentStatus(paymentId)
      .then(async (payment) => {
        if (payment.paid) {
          clearCart();
          await updateOrder(orderId, { paid: true });
          await navigateToPage("/order_status");
        } else {
          const error = { title: "Заказ не оплачен", message: "Заказ не оплачен. Попробуйте снова" };
          await navigateToPage("/cart", error);
        }
      })
      .catch((_) =>
        navigateToPage("/cart", {
          title: "Непредвиденная ошибка",
          message: "Ошибка. Пожалуйста, обратитесь к нам в службу поддержки",
        })
      );

    setLoading(false);
  }, [clearCart, orderId, paymentId]);

  useEffect(checkPayment, [checkPayment]);

  const navigateToPage = async (path, error = null) => {
    error
      ? await navigate(path, {
          state: { error: error },
        })
      : await navigate(path);
  };

  if (loading) {
    return (
      <div id='preloader'>
        <div id='loader' />
      </div>
    );
  } else {
    return null;
  }
};

export default connect(null, { clearCart })(PaymentValidator);
