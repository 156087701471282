import {
  ADD_INTO_CART,
  CHANGE_CART_ITEM_QUANTITY,
  CLEAR_CART,
  DELETE_FROM_CART,
  GET_CART_ITEMS,
  UPDATE_CART_ITEM,
} from "./types";
import isEqual from "lodash/isEqual";

const ordersEqual = (orderA, orderB) => {
  return (
    orderA.id === orderB.id && isEqual(orderA.additives, orderB.additives) && isEqual(orderA.modifiers, orderB.modifiers)
  );
};

export const addIntoCart = (menuItem) => {
  let updatedItemsList = [];
  const savedItems = localStorage.getItem("cart_items");
  if (!savedItems) {
    updatedItemsList.push(menuItem);
  } else {
    updatedItemsList = [...JSON.parse(savedItems)];
    const i = updatedItemsList.findIndex((el) => ordersEqual(el, menuItem));
    if (i >= 0) {
      updatedItemsList[i].quantity = updatedItemsList[i].quantity + menuItem.quantity;
    } else {
      updatedItemsList.push(menuItem);
    }
  }

  localStorage.setItem("cart_items", JSON.stringify(updatedItemsList));
  return {
    type: ADD_INTO_CART,
    payload: updatedItemsList,
  };
};

export const getCartItems = () => {
  const cartItems = localStorage.getItem("cart_items");
  const result = cartItems ? JSON.parse(cartItems) : [];
  return {
    type: GET_CART_ITEMS,
    payload: result,
  };
};

export const updateCartItem = (updatedCartItem) => {
  const savedCartItems = localStorage.getItem("cart_items");
  const cartItems = savedCartItems ? JSON.parse(savedCartItems) : [];
  const index = cartItems.findIndex(el => ordersEqual(el,updatedCartItem));
  if (index >= 0){
    cartItems[index] = updatedCartItem;
    localStorage.setItem("cart_items", JSON.stringify(cartItems));
  }
  return {
    type: UPDATE_CART_ITEM,
    payload: cartItems
  }
}

export const changeCartItemQuantity = (item) => {
  const savedCartItems = localStorage.getItem("cart_items");
  const cartItems = savedCartItems ? JSON.parse(savedCartItems) : [];
  const index = cartItems.findIndex((el) => ordersEqual(el, item));
  if (index >= 0) {
    cartItems[index].quantity = item.quantity;
    cartItems[index].totalPrice = item.totalPrice;
    localStorage.setItem("cart_items", JSON.stringify(cartItems));
  }
  return {
    type: CHANGE_CART_ITEM_QUANTITY,
    payload: cartItems,
  };
};

export const removeFromCart = (item) => {
  const savedCartItems = localStorage.getItem("cart_items");
  const cartItems = savedCartItems ? JSON.parse(savedCartItems) : [];
  const updatedCartItems = cartItems.filter((el) => !ordersEqual(el, item));

  if (updatedCartItems.length) {
    localStorage.setItem("cart_items", JSON.stringify(updatedCartItems));
  } else {
    localStorage.removeItem("cart_items");
  }
  return {
    type: DELETE_FROM_CART,
    payload: updatedCartItems,
  };
};

export const clearCart = () => {
  localStorage.removeItem("cart_items");
  return {
    type: CLEAR_CART,
    payload: []
  }
}
