import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import { deepOrange, red } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box, ButtonBase,
  CardActionArea,
  CardMedia, Fab,
  InputBase,
  Paper,
  Tab,
  Tabs
} from "@material-ui/core";
import NewLineText from "../features/NewLineText";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import OrderOrContinue from "./OrderOrContinue";
import Loader from "../features/Loader";
import MenuItemDetail from "./MenuItemDetail";
import RenderSmoothImage from "render-smooth-image-react";
import "render-smooth-image-react/build/style.css";
import PropTypes from "prop-types";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 350,
    borderRadius: "8px",
    backgroundColor: "transparent"
  },
  // fab: {
  //   position: 'fixed',
  //   bottom: theme.spacing(2),
  //   right: theme.spacing(2),
  //   zIndex: 1,
  //   paddingInline: 20
  // },
  cardActionArea: {
    "&:hover $focusHighlight": {
      opacity: 0
    }
  },
  focusHighlight: {},
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  expand: {
    height: 180
  },
  expandOpen: {},
  avatar: {
    backgroundColor: red[500]
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 50%"
  }
}));


const MenuList = ({ menu_list }) => {
  const classes = useStyles();
  const [expandedId, setExpandedId] = React.useState(-1);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [menuItemDialogOpen, setMenuItemDialogOpen] = useState(false);

  const [openOrderOrContinue, setOpenOrderOrContinue] = useState(false);

  const handleExpandClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const onMenuItemSelect = (menuItem) => {
    setSelectedMenuItem(menuItem);
    setMenuItemDialogOpen(true);
  };

  const onMenuItemUnselect = () => {
    setMenuItemDialogOpen(false);
    setSelectedMenuItem(null);
  };

  const handleMenuItemSubmit = () => {
    onMenuItemUnselect();
    setOpenOrderOrContinue(true);
  };

  if (!menu_list) {
    return <Loader />;
  }

  //Category Filter
  const [filterCategory, setFilterCategory] = useState(false);
  const [filterEnabled, setFilterEnabled] = useState(false);

  const onFilter = (filterCategory) => {
    setFilterCategory(filterCategory);
    setFilterEnabled(true);
  };
  const offFilter = () => {
    setFilterEnabled(false);
    setSearch(false);
    setSearchQuery("");
  };
  const uniqueCategories = [];
  console.log(uniqueCategories);
  menu_list.map(item => {
    if (uniqueCategories.indexOf(item.category.title) === -1) {
      uniqueCategories.push(item.category.title);
    }
  });

  //TABS
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // SEARCH

  const [searchQuery, setSearchQuery] = useState("");
  const [search, setSearch] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  const handleSetSearchQuery = (e) => {
    setFilterEnabled(false);
    setFilterCategory(false);
    setValue(0);
    setSearch(true);
    setSearchQuery(e.target.value);
  };

  const enableSearch = () => {
    if (search === false) {
      setFilterEnabled(false);
      setFilterCategory(false);
      setValue(0);
      setSearch(true);
    } else {
      setValue(0);
      setSearchQuery("");
      setSearch(false);
    }
  };
  const disableSearch = () => {
    setSearch(false);
  };

  const handleSearch = (query) => {
    const filtered = menu_list.filter((item) =>
      item.title.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  useEffect(() => {
    handleSearch(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    const handleTouchStart = () => {
      const activeElement = document.activeElement;
      if (activeElement && activeElement.blur) {
        activeElement.blur();
      }
    };

    window.addEventListener("touchstart", handleTouchStart);

    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);

  return (
    <Container maxWidth="md">
      {/*SEARCH*/}
      {search ?
        <Paper
          style={{
            marginBottom: 20,
            top: 0,
            position: "sticky",
            zIndex: 1,
            // background: "white",
            // background: "#9E9CEE",
            borderRadius: 10,
            paddingInline: 5,
            opacity: "90%",
            padding: 10
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <InputBase
                style={{
                  paddingInline: 5,
                  // color: "white",
                  // fontWeight: "bold"
                }}
                fullWidth
                placeholder="поиск"
                inputProps={{ "aria-label": "search" }}
                type="text"
                value={searchQuery}
                onChange={(e) => handleSetSearchQuery(e)}
              />
            </Grid>
            <Grid item xs={2} style={{ display: "flex", justifyContent: "flex-end", paddingInline: 10 }}>
              <ButtonBase
                onClick={enableSearch}
                onDragLeave={disableSearch}
                {...a11yProps(-1)}
              >
                <HighlightOffIcon style={{color: "grey", opacity: "80%"}} />
              </ButtonBase>
            </Grid>
          </Grid>
        </Paper>
        :
        <div
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Fab
            onClick={enableSearch}
            onDragLeave={disableSearch}
            {...a11yProps(-1)}
            style={{
              position: "fixed",
              bottom: 20,
              opacity: "80%",
              zIndex: 1,
              paddingInline: 10,
              paddingBlock: 5,
              // borderRadius: 10,
              background: "#9E9CEE"
            }}
          >
            <SearchIcon />
          </Fab>
        </div>
      }

      {/*FILTER*/}
      {search !== true &&
      <Tabs
        style={{ marginBottom: 20, top: 0, position: "sticky", zIndex: 1, background: "white", opacity: "90%" }}
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        scrollButtons="on"
        textColor="secondary"
        variant="scrollable"
        // scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {filterEnabled === true &&
        <Tab style={{ fontSize: 11, color: "#9E9CEE" }} label="сбросить фильтр" {...a11yProps(-1)}
             onClick={() => offFilter()} />
        }
        {filterEnabled === false &&
        <Tab style={{ fontSize: 11 }} label="все товары" {...a11yProps(-1)}
             onClick={() => offFilter()} />
        }
        {uniqueCategories.map((item, index) => (
          <Tab style={{ fontSize: 11, fontWeight: "bold" }} label={item} {...a11yProps(index)}
               onClick={() => onFilter(item)} />
        ))}
      </Tabs>
      }

      <Grid container spacing={2}>
        {(filterEnabled ? menu_list.filter(toFilterItem => toFilterItem.category.title === filterCategory) : filteredItems).map((item, i) => (
          <Grid key={item.title} item xs={12} sm={6} md={4}>
            <Card className={classes.root}>
              <CardActionArea
                classes={{
                  root: classes.cardActionArea,
                  focusHighlight: classes.focusHighlight
                }}
                onClick={() => onMenuItemSelect(item)}
              >
                {/*todo delete if RenderSmoothImage is ok */}
                {/*<CardMedia className={classes.media} image={item.photo} title={item.title} />*/}
                <div className={classes.media}>
                  <div className={classes.imageSrc}>
                    <RenderSmoothImage
                      objectFit={"cover"}
                      src={item.photo}
                    />
                  </div>
                </div>
              </CardActionArea>
              <CardContent style={{ marginTop: -10, marginBottom: -25 }}>
                <Typography style={{ fontSize: 13, textTransform: "uppercase" }} variant="caption"
                            color="textSecondary">
                  {item.title}
                </Typography>
              </CardContent>

              <CardActions>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexGrow: 1,
                    marginLeft: 8,
                    marginBottom: -5
                  }}
                >
                  <Typography variant={"caption"} style={{ fontSize: 14.5 }} color="textSecondary" display={"inline"}>
                    {item.portion_size?.portions[0]?.price ? item.portion_size.portions[0]?.price + "₽" : ""}
                  </Typography>
                  <IconButton onClick={() => handleExpandClick(i)}>
                    {expandedId !== i ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  </IconButton>
                </div>
              </CardActions>
              <Collapse in={expandedId === i} timeout="auto" unmountOnExit>
                <CardContent style={{ marginTop: -10, marginBottom: -20 }}>
                  {item.consist.length > 0 &&
                  <Typography variant={"caption"} color={"textSecondary"} paragraph>
                    <NewLineText text={item.consist} />
                  </Typography>
                  }
                  {item.short_description.length > 0 &&
                  <Typography variant={"caption"} color={"textSecondary"} paragraph>
                    <NewLineText text={item.short_description} />
                  </Typography>
                  }
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
        ))}
      </Grid>

      {menuItemDialogOpen && selectedMenuItem && (
        <MenuItemDetail
          open={menuItemDialogOpen}
          menuItem={selectedMenuItem}
          onClose={onMenuItemUnselect}
          onSendToCart={handleMenuItemSubmit}
        />
      )}

      <OrderOrContinue isOpen={openOrderOrContinue} onClose={() => setOpenOrderOrContinue(false)} />
    </Container>
  );
};
;

export default MenuList;
