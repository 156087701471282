export const renderDateTime = (dt) => {
    const orderCreated = new Date(dt);
    let dayOfMonth = orderCreated.getDate().toString().padStart(2, "0");
    const month = (orderCreated.getMonth() + 1).toString().padStart(2, "0");
    const year = orderCreated.getFullYear();
    const hour = orderCreated.getHours().toString().padStart(2, "0");
    const minutes = orderCreated.getMinutes().toString().padStart(2, "0");

    return `${dayOfMonth}.${month}.${year} ${hour}:${minutes}`;
};

// add hours to Date
export const addHours = (hours) => {
    const date = new Date();
    date.setHours(date.getHours() + hours);
    return date;
};

// add days
export const addDays = (days, date = new Date()) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

export const parseDateTime = (dt) => {
    const dtObject = new Date(dt);
    return {
        dayOfMonth: dtObject.getDate().toString().padStart(2, "0"),
        month: (dtObject.getMonth() + 1).toString().padStart(2, "0"),
        year: dtObject.getFullYear(),
        hour: dtObject.getHours().toString().padStart(2, "0"),
        minutes: dtObject.getMinutes().toString().padStart(2, "0"),
        seconds: dtObject.getSeconds().toString().padStart(2, "0"),
    };
};

export const DaysEngToRus = {
    monday: { full: "понедельник", short: "пн" },
    tuesday: { full: "вторник", short: "вт" },
    wednesday: { full: "среда", short: "ср" },
    thursday: { full: "четверг", short: "чт" },
    friday: { full: "пятница", short: "пт" },
    saturday: { full: "суббота", short: "сб" },
    sunday: { full: "воскресенье", short: "вс" },
};