import React, { useEffect, useState } from "react";
import { Grid, IconButton, makeStyles, Paper, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { grey } from "@material-ui/core/colors";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import QuantityDiv from "../features/QuantityDiv";
import { changeCartItemQuantity, removeFromCart, updateCartItem } from "../../actions/cartActions";
import bb from "../../api/boostBox";
import { calcTotalPrice } from "../../utils/menuItemUtils";

const useStyles = makeStyles({
  cardContent: {
    alignItems: "center",
    justifyContent: "space-between",
    background: grey[200],
    borderRadius: 10,
    marginBottom: "1em",
  },
  cartItemImage: {
    maxWidth: 100,
    maxHeight: 100,
    borderRadius: 10,
    alignItems: "start",
  },
  additive: {
    fontSize: "0.9rem",
    padding: 0,
  },
  closeButton: {
    color: "red",
    padding: 8,
  },
  ul: {
    marginBlockStart: "0.5em",
    marginBlockEnd: "0.5em",
  },
});

const CartItem = ({ cartItem: savedItem }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cartItem, setCartItem] = useState(savedItem);

  useEffect(() => {
    const loadMenuItemInfo = async () => {
      try {
        const menuItemInfo = await bb.Menu.details(savedItem.id);
        const portions_size = JSON.parse(menuItemInfo.portion_size);
        let portionPrice = portions_size.portions.filter((p) => p.size === savedItem.portion.size);
        portionPrice = portionPrice.length
          ? { ...portionPrice[0], unit: portions_size.unit }
          : { ...portions_size.portions[0], unit: portions_size.unit };

        let additives = [];
        for (let i = 0; i < savedItem.additives.length; i++) {
          const additivePrice = menuItemInfo.additive.filter((a) => a.id === savedItem.additives[i].id);
          if (additivePrice.length) additives.push(additivePrice[0]);
        }

        let modifiers = [];
        for (let j = 0; j < savedItem.modifiers.length; j++) {
          const modifier = menuItemInfo.select_additive.filter((m) => m.id === savedItem.modifiers[j].id);

          if (!modifier.length) continue;
          const modifierPrice = modifier[0].select_items.filter((item) => item.id === savedItem.modifiers[j].item.id);

          if (!modifierPrice.length) continue;
          modifiers.push({ title: modifier[0].title, id: modifier[0].id, item: modifierPrice[0] });
        }

        const updated = {
          ...savedItem,
          title: menuItemInfo.title,
          photo: menuItemInfo.photo,
          short_description: menuItemInfo.short_description,
          portion: portionPrice,
          additives,
          modifiers,
          totalPrice: calcTotalPrice({ portion: portionPrice, quantity: savedItem.quantity, additives, modifiers }),
        };
        dispatch(updateCartItem(updated));
        setCartItem(updated);
      } catch (e) {
        console.log(e.message);
        if (e.response?.status === 404) {
          dispatch(removeFromCart(savedItem));
        }
      }
    };
    loadMenuItemInfo();
    // todo: think of another implementation (without savedItem dependency)
  }, [savedItem.id, dispatch]);

  const onQuantityChange = (updatedQuantity) => {
    if (updatedQuantity < 1) {
      dispatch(removeFromCart(cartItem));
      return;
    }
    const updatedPrice = calcTotalPrice({
      portion: cartItem.portion,
      quantity: updatedQuantity,
      additives: cartItem.additives,
      modifiers: cartItem.modifiers,
    });

    setCartItem({
      ...cartItem,
      quantity: updatedQuantity,
      totalPrice: updatedPrice,
    });
    dispatch(changeCartItemQuantity({ ...cartItem, quantity: updatedQuantity, totalPrice: updatedPrice }));
  };

  return (
    <Grid container spacing={2} className={classes.cardContent}>
      <Grid item style={{textAlign: "center"}} xs={6} md={2} sm={2}>
        <img alt={cartItem.title} src={cartItem.photo} className={classes.cartItemImage} />
        <br/>
        <Typography variant={"overline"}>{cartItem.title}</Typography>
      </Grid>
      {/*<Grid item xs={12} md={3}>*/}
      {/*  <Typography variant={"body1"}>{cartItem.title}</Typography>*/}
      {/*</Grid>*/}
      <Grid item xs={6} md={4}>
        <div className={classes.additive}>
          <Paper variant={"outlined"} style={{ textAlign: "center", marginBottom: 5, borderRadius: 30 }}>
            <Typography variant={"caption"}>Размер порции</Typography>{" "}
          </Paper>
          <Typography variant={"caption"}>
            {/*<ul className={classes.ul}>*/}
              <li style={{ marginLeft: 15}}>
                {cartItem.portion["size"]} {cartItem.portion.unit}
              </li>
            {/*</ul>*/}
          </Typography>
        </div>

        {cartItem.additives.length > 0 && (
          <div className={classes.additive}>
            <Paper variant={"outlined"} style={{ textAlign: "center", marginBottom: 5, borderRadius: 30 }}>
              <Typography variant={"caption"}>Дополнительно</Typography>{" "}
            </Paper>

            {cartItem.additives.map((additive) => (
              <Typography variant={"caption"} key={additive.id}>
                {/*<ul className={classes.ul}>*/}
                  <li style={{ marginLeft: 15}}>{additive.title}</li>
                {/*</ul>*/}
              </Typography>
            ))}
          </div>
        )}

        {cartItem.modifiers.length > 0 && (
          <React.Fragment>
            {cartItem.modifiers.map((modifier) => (
              <React.Fragment key={modifier.title}>
                <Paper variant={"outlined"} style={{ textAlign: "center", marginBottom: 5, borderRadius: 30 }}>
                  <Typography variant={"caption"}>{modifier.title}</Typography>
                </Paper>
                <Typography variant={"caption"} key={modifier.title}>
                  {/*<ul className={classes.ul}>*/}
                    <li style={{marginLeft: 15}}>{modifier.item.title}</li>
                  {/*</ul>*/}
                </Typography>
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
      </Grid>
      <Grid item>
        <QuantityDiv
          quantity={cartItem.quantity}
          onDecrement={() => onQuantityChange(cartItem.quantity - 1)}
          onIncrement={() => onQuantityChange(cartItem.quantity + 1)}
        />
      </Grid>
      <Grid item>
        <Typography>{cartItem.totalPrice}</Typography>
      </Grid>
      <Grid item>
        <IconButton className={classes.closeButton} onClick={() => dispatch(removeFromCart(cartItem))}>
          <CloseRoundedIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CartItem;
