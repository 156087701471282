import React from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Modal from "../features/Modal";

const useStyles = makeStyles({
    title: {
        paddingTop: 12,
        lineHeight: 1.4,
    },
    policyContent: {
        textAlign: "justify",
        lineHeight: 1.5,
        color: "rgba(0, 0, 0, 0.54)",
    },
});

const PrivacyPolicy = (props) => {
    const { open, onClose } = props;
    const classes = useStyles();

    const renderTitle = () => {
        return (
            <Typography className={classes.title} variant={"h6"}>
                Соглашение на обработку персональных данных
            </Typography>
        );
    };

    const renderContent = () => {
        return (
            <div className={classes.policyContent}>
                <Typography>
                    Предоставляя свои персональные данные Покупатель даёт согласие на обработку, хранение и
                    использование своих персональных данных на основании ФЗ № 152-ФЗ «О персональных данных» от
                    27.07.2006 г. в следующих целях:
                </Typography>
                <ul>
                    <li>Регистрации Пользователя на сайте</li>
                    <li>Осуществление клиентской поддержки</li>
                    <li>Получения Пользователем информации о маркетинговых событиях</li>
                    <li>Выполнение Продавцом обязательств перед Покупателем</li>
                    <li>
                        Проведения аудита и прочих внутренних исследований с целью повышения качества предоставляемых
                        услуг.
                    </li>
                </ul>
                <Typography>
                    Под персональными данными подразумевается любая информация личного характера, позволяющая установить
                    личность Покупателя такая как:
                </Typography>
                <ul>
                    <li>Фамилия, Имя, Отчество</li>
                    <li>Дата рождения</li>
                    <li>Контактный телефон</li>
                    <li>Адрес электронной почты</li>
                    <li>Почтовый адрес</li>
                </ul>
                <Typography>
                    Персональные данные Покупателей хранятся исключительно на электронных носителях и обрабатываются с
                    использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная
                    обработка персональных данных необходима в связи с исполнением требований законодательства.
                </Typography>
                <Typography>
                    Продавец обязуется не передавать полученные персональные данные третьим лицам, за исключением
                    следующих случаев:
                </Typography>
                <ul>
                    <li>
                        По запросам уполномоченных органов государственной власти РФ только по основаниям и в порядке,
                        установленным законодательством РФ
                    </li>
                    <li>
                        Стратегическим партнерам, которые работают с Продавцом для предоставления продуктов и услуг, или
                        тем из них, которые помогают Продавцу реализовывать продукты и услуги потребителям. Мы
                        предоставляем третьим лицам минимальный объем персональных данных, необходимый только для
                        оказания требуемой услуги или проведения необходимой транзакции.
                    </li>
                </ul>
                <Typography>
                    Продавец оставляет за собой право вносить изменения в одностороннем порядке в настоящие правила, при
                    условии, что изменения не противоречат действующему законодательству РФ. Изменения условий настоящих
                    правил вступают в силу после их публикации на Сайте.
                </Typography>
            </div>
        );
    };

    const renderActions = () => {
        return (
            <Button onClick={onClose} color={"secondary"}>
                OK
            </Button>
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={renderTitle()}
            content={renderContent()}
            actions={renderActions()}
            scroll={"body"}
            maxWidth={"sm"}
            fullscreen
        />
    );
};

export default PrivacyPolicy;
