import axios from "axios";

const boostBoxClient = axios.create({
  baseURL: "https://backend.boost-box.ru/backend/api/v1/",
});

const responseBody = (response) => response.data;

const requests = (axiosInstance) => {
  return {
    get: (url, params) => axiosInstance.get(url, params ? { params } : null).then(responseBody),
    post: (url, body) => axiosInstance.post(url, body).then(responseBody),
    put: (url, body) => axiosInstance.put(url, body).then(responseBody),
    patch: (url, data) => axiosInstance.patch(url, data).then(responseBody),
  };
};

const setAuthToken = (token) => {
  boostBoxClient.defaults.headers.common["Authorization"] = "Token " + token;
};

const runRequest = async (request, params) => {
  try {
    const response = await request(params);
    return { success: true, error: null, result: response };
  } catch (e) {
    console.log(e);
    return { success: false, error: e.response.data, result: null };
  }
};

export { boostBoxClient, responseBody, requests, runRequest, setAuthToken };
