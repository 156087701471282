import { FETCH_ALL_USER_ORDERS, FETCH_CURRENT_ORDER, POST_ORDER } from "../actions/types";

const initialState = {
  history: [],
  orders: [],
  currentOrder: null
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_USER_ORDERS:
      return { ...state, history: action.payload };
    case FETCH_CURRENT_ORDER:
      return { ...state, orders: action.payload };
    case POST_ORDER:
      return { ...state, orders: [...state.orders, action.payload] };
    default:
      return state;
  }
};

export default ordersReducer;
