import React from "react";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles({
  quantityDiv: {
    display: "flex",
    border: "1px solid #cac8c8",
    borderRadius: 15,
    // padding: ""
    alignItems: "center",
    "& p": {
      fontSize: "1.1rem",
    },
  },
  quantityActionBtn: {
    padding: "8px 12px",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
});

const QuantityOutlinedDiv = ({ quantity, onIncrement, onDecrement }) => {
  const classes = useStyles();
  return (
    <div className={classes.quantityDiv}>
      <IconButton className={classes.quantityActionBtn} disableRipple onClick={onDecrement}>
        <RemoveIcon />
      </IconButton>
      <Typography>{quantity}</Typography>
      <IconButton className={classes.quantityActionBtn} disableRipple onClick={onIncrement}>
        <AddIcon />
      </IconButton>
    </div>
  );
};

QuantityOutlinedDiv.defaultProps = {
  quantity: 1,
};

export default QuantityOutlinedDiv;
