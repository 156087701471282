import { boostBoxClient, requests } from "./index";

const Organizations = {
  list: () => requests(boostBoxClient).get("/organization/"),
  details: (params) => requests(boostBoxClient).get("/organization/", params),
};

const Restaurants = {
  list: (params) => requests(boostBoxClient).get("/restaurant/", params),
  details: (restaurantId) => requests(boostBoxClient).get(`/restaurant/${restaurantId}/`),
};

const User = {
  register: (params) => requests(boostBoxClient).post("/register/", params),
  authorize: (params) => requests(boostBoxClient).post("/auth/", params),
};

const Orders = {
  list: (params) => requests(boostBoxClient).get("/order/", params),
  details: (orderId) => requests(boostBoxClient).get(`/order/${orderId}/`),
  create: (order) => requests(boostBoxClient).post("/order/", order),
};

const Menu = {
  details: (menuItemId) => requests(boostBoxClient).get(`/menu/${menuItemId}/`)
}

const bb = { Organizations, Restaurants, Orders, User, Menu };

export default bb;
