export const FETCH_MENU = "FETCH_MENU";
export const SET_CLIENT_PARAMS = "CHANGE_CLIENT_PARAMS";
export const CHANGE_TABLE_NUMBER = "CHANGE_TABLE_NUMBER";
export const CHANGE_PAYMENT_METHOD = "CHANGE_PAYMENT_METHOD";
export const TABLE_NUMBER_ERROR = "TABLE_NUMBER_ERROR";
export const TABLE_NUMBER_EMPTY = "TABLE_NUMBER_EMPTY";
export const OPEN_CLIENT_PARAMS_DIALOG = "OPEN_CLIENT_PARAMS_DIALOG";
export const CLOSE_CLIENT_PARAMS_DIALOG = "CLOSE_CLIENT_PARAMS_DIALOG";
export const GET_USER_ID = "GET_USER_ID";

export const ADD_INTO_CART = "ADD_INTO_CART";
export const UPDATE_CART_ITEM_STATE = "UPDATE_CART_ITEM_STATE";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const GET_CART_ITEMS = "GET_CART_ITEMS";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const CHANGE_CART_ITEM_QUANTITY = "CHANGE_CART_ITEM_QUANTITY";
export const CLEAR_CART = "CLEAR_CART";
export const APPLY_DISCOUNT = "APPLY_DISCOUNT";
export const RESET_DISCOUNT = "RESET_DISCOUNT";

export const POST_ORDER = "POST_ORDER";
export const SAVE_ORDER = "SAVE_ORDER";
export const CREATE_ORDER = "CREATE_ORDER";
export const FETCH_CURRENT_ORDER = "FETCH_CURRENT_ORDER";
export const UPDATE_ORDERS = "UPDATE_ORDERS";
export const FETCH_ALL_USER_ORDERS = "FETCH_ALL_USER_ORDERS";
export const SAVE_USER_ADDRESS = "SAVE_USER_ADDRESS";
export const GET_USER_ADDRESS = "GET_USER_ADDRESS";

export const POST_ORDER_ERROR = "POST_ORDER_ERROR";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";


export const GET_USER_CREDENTIALS = "GET_USER_CREDENTIALS";
export const REGISTER_USER = "REGISTER_USER";

export const GET_ORGANIZATION_DATA = "GET_ORGANIZATION_DATA";