import * as actions from "./types";
import { boostBoxClient as cafeApi } from "../api";
import CookieHandler from "../utils/cookieHandler";
import { getUserOrders, sendPromoCode } from "../api/green_bowl";
import { navigate } from "@reach/router";
import { getUserId } from "./userActions";
import axios from "axios";
import { parseDateTime } from "../utils/datetimeUtils";

export const getDeliveryPrice = async (order) => {
  const response = await cafeApi.post("/delivery/get_price", {
    client: order.client,
    phone: order.phone,
    endpoint_address: order.endpoint_address,
    lat: order.lat,
    long: order.long,
    porch: order.porch,
    floor: order.floor,
    flat: order.flat,
    door_code: order.door_code,
    order_amount: order.order_amount,
  });

  return response.data;
};

export const saveOrder = async (order) => {
  const orderData = {
    ...order,
    contact_information: JSON.stringify(order.contact_information),
    order: JSON.stringify(order.order),
    status: 0,
  };
  navigate("/payment", { state: { order: order } });
  return {
    type: actions.SAVE_ORDER,
    payload: orderData,
  };
};

export const postOrder = (order) => async (dispatch, getState) => {
  const { organization, user } = getState();
  const orderData = {
    ...order,
    contact_information: JSON.stringify(order.contact_information),
    order: JSON.stringify(order.order),
    order_price: order.order_price,
    delivery: order.delivery,
    delivery_price: order.delivery_price,
    status: 0,
    user_id: user.user_id,
    organization_id: organization.organizationId,
    restaurant_id: organization.currentRestaurant,
  };
  try {
    const response = await cafeApi.post("/order/", orderData);
    // throw { message: "error" };
    dispatch({ type: actions.POST_ORDER, payload: order });
    return response.data.id;
  } catch (e) {
    console.log(e);
    dispatch({
      type: actions.POST_ORDER_ERROR,
      payload: {
        type: actions.POST_ORDER_ERROR,
        message: "Что-то пошло не так. Попробуйте повторить позднее",
        data: e,
      },
    });
    throw e;
  }
};

export const updateOrders = (orders) => {
  let relevantOrders = orders.filter((el) => new Date(el.created).toDateString() === new Date().toDateString());

  if (relevantOrders) {
    relevantOrders.forEach((item) => (item.order = JSON.parse(item.order)));
  }
  let result = relevantOrders.map((el) => {
    return el.order.map((orderItem) => {
      return { ...orderItem, status: el.status };
    });
  });
  result = result.flat();
  return {
    type: actions.UPDATE_ORDERS,
    payload: result,
  };
};

export const fetchOrder = async () => {
  const userId = getUserId();
  if (!userId) {
    console.log("no user id");
    return {
      type: actions.FETCH_CURRENT_ORDER, // todo: alarm
      payload: [],
    };
  }
  const response = await getUserOrders(userId);
  const relevantOrders = response.filter((el) => new Date(el.created).toDateString() === new Date().toDateString());
  if (relevantOrders) {
    relevantOrders.forEach((el) => (el.order = JSON.parse(el.order)));
  }
  return {
    type: "FETCH_CURRENT_ORDER",
    payload: response,
  };
};

export const fetchCompletedUserOrders = async (userId) => {
  const response = await cafeApi.get("/order/", {
    params: {
      user_id: userId,
      status: 4,
    },
  });
  const result = response.data;
  result.forEach((orderItem) => (orderItem.order = JSON.parse(orderItem.order)));
  return result;
};

export const repeatOrder = (values) => {
  const cartItems = values.map((item) => {
    return {
      id: item.id,
      title: item.title,
      portion: item.portion,
      quantity: item.quantity,
      additives: item.additives,
      modifiers: item.modifiers,
      photo: item.photo,
      price: item.price,
      totalPrice: item.totalPrice,
    };
  });

  localStorage.setItem("cart_items", JSON.stringify(cartItems));

  navigate("/cart");
  return {
    type: actions.ADD_INTO_CART,
    payload: cartItems,
  };
};

const today = new Date();
const yesterday = new Date();
yesterday.setDate(today.getDate());

export const fetchCurrentOrders = (userId) => async (dispatch) => {
  const response = await cafeApi.get("/order/", {
    params: {
      user_id: userId,
      exclude_status: 4,
      created_gte: `${parseDateTime(yesterday).year}-${parseDateTime(yesterday).month}-${parseDateTime(yesterday).dayOfMonth}`
    },
  });
  const result = response.data;
  // const result = response.data?.results?.filter(
  //   (orderItem) => new Date(orderItem.created).toDateString() === new Date().toDateString()
  // );
  result.forEach((orderItem) => (orderItem.order = JSON.parse(orderItem.order)));
  dispatch({ type: actions.FETCH_CURRENT_ORDER, payload: result });
};

export const getOrCreateUserId = () => {
  let userId = CookieHandler.getUserId();
  if (!userId) {
    userId = CookieHandler.setUserId();
  }
  return userId;
};

export const getItemParams = async (item) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const response = await cafeApi.get(`/menu/${item.id}/`, {
    cancelToken: source.token,
  });
  source.cancel();

  item.photo = response.data.photo;
  item.short_description = response.data.short_description;
  item.price = response.data.price;
  return item;
};

export const fetchDiscount = (promoCode) => async (dispatch) => {
  const customerAddress = localStorage.getItem("address");
  let phone = "";

  if (customerAddress) {
    phone = JSON.parse(customerAddress).phone;
  }

  const codeValue = promoCode.toLowerCase().trim();

  const response = await sendPromoCode(codeValue, phone);
  if (!isNaN(response) && !isNaN(parseFloat(response))) {
    const discount = parseFloat(response);
    dispatch({
      type: actions.APPLY_DISCOUNT,
      payload: { [codeValue]: discount },
    });
  } else {
    return response;
  }
};

export const resetDiscount = (cartItems) => {
  return {
    type: actions.RESET_DISCOUNT,
    payload: cartItems,
  };
};

// АДМИНКА
export const fetchActiveOrders = (userId) => async (dispatch) => {
  const response = await cafeApi.get("/order/", {
    params: {
      user_id: userId,
      exclude_status: 4,
    },
  });
  const result = response.data;
  result.forEach((orderItem) => (orderItem.order = JSON.parse(orderItem.order)));
  dispatch({ type: actions.FETCH_ALL_USER_ORDERS, payload: result });
};
