import { GET_USER_ID, SAVE_USER_ADDRESS, GET_USER_ADDRESS, GET_USER_CREDENTIALS, REGISTER_USER } from "./types";
import CookieHandler from "../utils/cookieHandler";
import { v4 as uuidv4 } from "uuid";
import bb from "../api/boostBox";
import { setAuthToken } from "../api";

export const getUserId = () => {
  return {
    type: GET_USER_ID,
    payload: CookieHandler.getUserId(),
  };
};

export const getUserCredentials = () => {
  let credentials = localStorage.getItem("user_data");
  if (!credentials) return { type: GET_USER_CREDENTIALS, success: false, payload: null };

  credentials = JSON.parse(credentials);

  if (!credentials.token || !credentials.user_id) return { type: GET_USER_CREDENTIALS, success: false, payload: null };

  setAuthToken(credentials.token);

  return { type: GET_USER_CREDENTIALS, success: true, payload: credentials };
};

export const tryRegisterUser = (userData) => async (dispatch) => {
  const password = uuidv4().replace(/-/g, "");
  let response = await registerUser({ ...userData, password, phone: userData.phone });

  let tries = 0;
  while (tries < 5) {
    const rand = Math.random().toString(16).substr(2, 8);
    response = await registerUser({ ...userData, password, phone: userData.phone + "-" + rand });
    if (response.success) break;
    tries++;
  }
  if (!response.success) {
    dispatch({ type: REGISTER_USER, success: false, error: response.error });
    return { success: false, error: response.error, result: null };
  }

  const authorize = await authUser({ username: response.result.phone, password: password });

  if (authorize.success) {
    localStorage.setItem("user_data", JSON.stringify(authorize.result));
    setAuthToken(authorize.result.token);
    dispatch({ type: REGISTER_USER, success: true, error: null, payload: authorize.result });
    return { success: true, result: authorize.result };
  } else {
    dispatch({ type: REGISTER_USER, success: false, error: authorize.error, payload: null });
    return { success: false, error: authorize.error, result: null };
  }
};

const registerUser = async (userData) => {
  try {
    const response = await bb.User.register(userData);
    return {
      success: true,
      result: response,
      error: null,
    };
  } catch (e) {
    console.log(e.response.statusText, e.response.data);
    return {
      success: false,
      result: null,
      error: e.response.data,
    };
  }
};

export const authUser = async (userData) => {
  try {
    const response = await bb.User.authorize(userData);
    return {
      success: true,
      error: null,
      result: response,
    };
  } catch (e) {
    console.log(e);
    return {
      success: false,
      error: e.response,
    };
  }
};

// todo: refactor this
export const saveAddress = (address, delivery = false) => {
  let userData = localStorage.getItem("user_data");
  if (!userData) {
    localStorage.setItem("user_data", JSON.stringify({address}))
    return {
      type: SAVE_USER_ADDRESS,
      payload: address,
    };
  }

  userData = JSON.parse(userData);
  if (delivery) {
    localStorage.setItem("user_data", JSON.stringify({...userData, address}));
  } else {
    if (userData.address) {
      userData.address.name = address.name;
      userData.address.phone = address.phone;
      userData.address.instagram = address.instagram;
      localStorage.setItem("user_data", JSON.stringify(userData));
    } else {
      localStorage.setItem("user_data", JSON.stringify({...userData, address}));
    }
  }
  return {
    type: SAVE_USER_ADDRESS,
    payload: address,
  };
};

export const getUserAddress = () => async (dispatch) => {
  let userData = localStorage.getItem("user_data");
  if (!userData) {
    return;
  }
  userData = JSON.parse(userData);
  dispatch({ type: GET_USER_ADDRESS, payload: userData.address });
};
