import bbBase from "../api/boostBox";
import { GET_ORGANIZATION_DATA } from "./types";

export const getOrganizationInfo = () => async (dispatch) => {
  try {
    const organizationResponse = {
      type: GET_ORGANIZATION_DATA,
      success: false,
      payload: { organizationId: null, domainName: null, restaurants: [] },
    };
    const urlSplit = window.location.href.split(".");
    if (urlSplit.length < 2) {
      dispatch(organizationResponse);
      return;
    }

    const domain = urlSplit[0].split("//").pop();
    if (!domain) {
      dispatch(organizationResponse);
      return;
    }

    const organization = await bbBase.Organizations.details({ domain_name: domain });
    if (organization.length > 0) {
      dispatch({
        ...organizationResponse,
        success: true,
        payload: {
          organizationId: organization[0].id,
          title: organization[0].title,
          domainName: organization[0].domain_name,
          restaurants: organization[0].restaurants,
          paymentsEnabled: organization[0].payments_enabled,
          deliveryEnabled: organization[0].delivery_enabled,
        },
      });
      return;
    }
    dispatch(organizationResponse);
  } catch (e) {
    console.log(e);
  }
};
