import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {fetchCurrentOrders} from "../../actions";
import Loader from "../features/Loader";
import {Chip, Fab, Paper} from "@material-ui/core";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import {brown, deepOrange, grey} from "@material-ui/core/colors";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: 5,
        alignItems: "center",
        borderRadius: 10,
        // background: brown[50]
    },
    title: {
        fontSize: "1em",
    },
    price: {
        textAlign: "right",
    },
    image: {
        maxWidth: 100,
        borderRadius: 10,
        width: "100%",
    },
    stepper: {
        padding: "24px 0",
    },
    additives_block: {
        fontSize: "0.9em",
        textAlign: "left",
    },
    additiveCategoryTitle: {
        fontStyle: "italic",
    },
    additiveList: {
        paddingLeft: "0.7em",
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    orders_info: {
        padding: "10px 16px 10px 16px",
        textAlign: "right",
    },
    orderNumber: {
        fontSize: "0.9rem",
        width: "12ch",
        background: "#efefef",
        border: "1px solid #d2d2d2",
        borderRadius: 4,
        textAlign: "center",
        padding: "2px 2px",
    },
    orderItem: {
        alignItems: "center",
        justifyContent: "space-between",
        padding: "6px 0px",
        background: grey[100],
        marginBottom: 20,
        borderRadius: 10,
    },
    categoryTitle: {
        borderRadius: 30,
        // marginTop: 10,
        marginBottom: 5,
        textAlign: "center"
    },
    categoryTitleName: {
        marginLeft: 10,
    },
    categoryTitleItems: {
        marginLeft: 10,
        marginTop: 3
    }

}));

const orderStepsDelivery = ["Новый", "Принят", "Готов", "В пути"];
const orderStepsWithoutDelivery = ["Новый", "Принят", "Готов"];

function HorizontalLabelPositionBelowStepper(props) {
    const {fetchCurrentOrders, orders, user_id} = props;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let t = 0;
        if (user_id) {
            setLoading(true);
            fetchCurrentOrders(user_id).then(setLoading(false));
            t = setInterval(() => fetchCurrentOrders(user_id), 5000);
        }

        return () => {
            clearInterval(t);
        };
    }, [user_id, fetchCurrentOrders]);

    const renderOrderItem = (order) => {
        return (
            <Card key={order.id}
                  style={order.status === 9 ? {opacity: 0.5} : null}
                  className={classes.root}
            >
                <CardContent>
                    <Grid
                        style={{textAlign: "right", marginBottom: 25, marginRight: 10, fontWeight: "bold"}}
                        item
                        xs={12}
                        md={12}
                    >
                        {order.paid === true && (
                            <Chip
                                size={"small"}
                                icon={<CreditCardIcon/>}
                                color={"secondary"}
                                variant={"outlined"}
                                label={"Оплачен"}
                            />
                        )}
                        {order.paid === false && (
                            <Chip
                                size={"small"}
                                icon={<CreditCardIcon/>}
                                color={"primary"}
                                variant={"outlined"}
                                label={"Не оплачен"}
                            />
                        )}
                        {order.status === 9 && (
                            <Chip
                                style={{
                                    marginLeft: 5,
                                    backgroundColor: deepOrange[500],
                                    color: "white",
                                }}
                                size={"small"}
                                // icon={<BlockIcon/>}
                                // color={{}}
                                variant={"outlined"}
                                label={"Отменен"}
                            />
                        )}
                    </Grid>
                    {order.order.map((orderItem) => (
                        <Grid key={orderItem.id} container spacing={2} className={classes.orderItem}>
                            <Grid style={{textAlign: "center"}} item xs={5} md={2}>
                                <Badge
                                    color={"secondary"}
                                    overlap='circular'
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    badgeContent={
                                        <Typography
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                            variant={"caption"}
                                        >
                                            &#215; {orderItem.quantity}
                                        </Typography>
                                    }
                                >
                                    <img className={classes.image} alt={orderItem.title} src={orderItem.photo}/>
                                </Badge>
                                <br/>
                                <Typography variant={"overline"}>
                                    {orderItem.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={7} md={4}>
                                <div>
                                    <Paper variant={"outlined"} className={classes.categoryTitle}>
                                        <Typography className={classes.categoryTitleName} variant={"caption"}>
                                            Размер порции
                                        </Typography>
                                    </Paper>
                                    <li style={{marginLeft: 20}}>
                                        <Typography variant={"caption"}>
                                            {/*<Grid container>*/}
                                            {/*<Grid item xs={8} md={8}>*/}
                                            {orderItem.portion["size"]} {orderItem.portion.unit}
                                            {/*</Grid>*/}
                                            {/*<Grid item xs={4} md={4}>*/}
                                            {/*    x{orderItem.quantity}*/}
                                            {/*</Grid>*/}
                                            {/*</Grid>*/}
                                        </Typography>
                                    </li>
                                </div>
                                {orderItem.additives.length > 0 && (
                                    <React.Fragment>
                                        <Paper variant={"outlined"} className={classes.categoryTitle}>
                                            <Typography className={classes.categoryTitleName}
                                                        variant={"caption"}>Дополнительно</Typography>
                                        </Paper>
                                        {orderItem.additives.map((additive) => (
                                            <li style={{marginLeft: 20}}>
                                                <Typography variant={"caption"}
                                                            key={additive.id}>
                                                    {/*<Grid container>*/}
                                                    {/*    <Grid item xs={8} md={8}>*/}
                                                    {additive.title}
                                                    {/*</Grid>*/}
                                                    {/*<Grid item xs={4} md={4}>*/}
                                                    {/*    x{orderItem.quantity}*/}
                                                    {/*</Grid>*/}
                                                    {/*</Grid>*/}
                                                </Typography>
                                            </li>
                                        ))}
                                    </React.Fragment>
                                )}

                                {orderItem.modifiers &&
                                    orderItem.modifiers.map((modifier) =>
                                        modifier.title === "None" || modifier.title === "Без соуса" ? null : (
                                            <div key={modifier.item.title}>
                                                <Paper variant={"outlined"} className={classes.categoryTitle}>
                                                    <Typography
                                                        className={classes.categoryTitleName}
                                                        variant={"caption"}
                                                    >
                                                        {modifier.title}
                                                    </Typography>
                                                </Paper>
                                                <li style={{marginLeft: 20}}>
                                                    <Typography variant={"caption"}>
                                                        {/*<Grid container>*/}
                                                        {/*    <Grid item xs={8} md={8}>*/}
                                                        {modifier.item.title}
                                                        {/*</Grid>*/}
                                                        {/*<Grid item xs={4} md={4}>*/}
                                                        {/*    x{orderItem.quantity}*/}
                                                        {/*</Grid>*/}
                                                        {/*</Grid>*/}
                                                    </Typography>
                                                </li>
                                            </div>
                                        )
                                    )}
                            </Grid>
                        </Grid>
                    ))}
                    <Grid container>
                        {order.status !== 9 && (
                            <Grid item xs={12}>
                                {order.delivery
                                    ? renderedStepper(orderStepsDelivery, order.status)
                                    : renderedStepper(orderStepsWithoutDelivery, order.status)}
                            </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                            <div className={classes.orderNumber}>№ {order.id}</div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography style={{textAlign: "right"}}>Сумма
                                заказа: {order.order_price}&#8381;</Typography>
                            {order.delivery && (
                                <Typography
                                    style={{textAlign: "right"}}>Доставка: {order.delivery_price}&#8381;</Typography>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    };

    const renderedStepper = (steps, activeStep) => {
        return (
            <Stepper alternativeLabel className={classes.stepper} activeStep={activeStep}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        );
    };

    if (loading) {
        return <Loader/>;
    }

    if (orders.length === 0) {
        return <Typography>Нет заказов, доступных для просмотра</Typography>;
    }

    return <React.Fragment>{orders.map((item) => renderOrderItem(item))}</React.Fragment>;
}

const mapStateToProps = (state) => {
    return {
        user_id: state.user.user_id,
        orders: state.orders.orders,
    };
};

export default connect(mapStateToProps, {fetchCurrentOrders})(HorizontalLabelPositionBelowStepper);
