import React from "react";
import { Link } from "@reach/router";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const NavListItem = ({ icon, title, to }) => {
  return (
    <ListItem button key={title} component={Link} to={to} color={"inherit"} underline={"none"}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{title}</ListItemText>
    </ListItem>
  );
};

export default NavListItem;
