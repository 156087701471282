import { POST_ORDER_ERROR } from "../actions/types";

const initialState = {
  hasErrors: false,
  error: {},
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_ORDER_ERROR:
      return {
        ...state,
        hasErrors: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default errorReducer;
