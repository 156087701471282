import React, { useCallback, useEffect, useState } from "react";
import { navigate } from "@reach/router";
import { connect } from "react-redux";
import useScript from "../../hooks/useScript";
import { postOrder } from "../../actions";
import { createPayment } from "../../api/yookassa";
import { deliveryCreateDraft } from "../../api/green_bowl";
import Loader from "../features/Loader";

const YookassaForm = (props) => {
  useScript("https://yookassa.ru/checkout-widget/v1/checkout-widget.js");
  const order = props.location.state ? props.location.state.order : null;
  const { postOrder } = props;
  const [loading, setLoading] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);
  // const returnUrl = "https://gb-salads.ru/payment_check";
  const returnUrl = "https://baso.boost-box.ru/payment_check";

  const renderCheckout = useCallback(() => {
    const handleError = async () => {
      setHasErrors(true);
      await navigateToPage("/cart", { message: "Что-то пошло не так. Попробуйте повторить позднее" });
    };

    const saveOrder = async (payment) => {
      try {
        let createDraftResponse = null;
        if (order.delivery) {
          createDraftResponse = await deliveryCreateDraft(order.contact_information);
        }
        const response = await postOrder({
          ...order,
          payment_id: payment.id,
          contact_information: {
            ...order.contact_information
          },
          delivery_id: createDraftResponse?.id,
        });
        order.id = response;

        return response;
      } catch (e) {
        await handleError();
      }
    };

    const initializeCheckout = (confirmationToken, payment, url = returnUrl) => {
      return new window.YooMoneyCheckoutWidget({
        confirmation_token: confirmationToken,
        return_url: `${url}/${order.id}/${payment.id}`,
        error_callback(error) {
          console.log(error);
          handleError();
          // handleInitError(error);
        },
      });
    };

    if (!order) {
      return;
    }
    setLoading(true);
    createPayment(order)
      .then(async (payment) => {
        if (!payment) {
          handleError();
        } else {
          await saveOrder(payment);
          return payment;
        }
      })
      .then((payment) => {
        if (!payment) {
          handleError();
        } else {
          const checkout = initializeCheckout(payment.confirmation.confirmation_token, payment);
          setLoading(false);
          checkout.render("payment-form");
        }
      });
  }, [order, postOrder]);

  useEffect(renderCheckout, [renderCheckout]);

  const navigateToPage = async (path = "/", error = null) => {
    error ? await navigate(path, { state: { error: error } }) : await navigate(path);
  };

  if (!order) {
    navigateToPage();
    return null;
  }

  if (loading || hasErrors) {
    return <Loader />;
  }

  return <div id='payment-form' />;
};

export default connect(null, { postOrder })(YookassaForm);
